var site = site || {};
site.endeca = site.endeca || {};
var rb = rb || {};
rb.endeca = rb.endeca || {};
site.endeca.result.product = jQuery.extend(true, {}, site.endeca.result.product, {
  initialize: function(args) {
    this._super(args);
    if (this.resultData.MISC_FLAG === 1) {
      this.resultData.MISC_FLAG_TEXT = rb.endeca.misc_flag_new;
    }
    this.resultData.formattedPrice = this.resultData.skus[0].formattedPrice;
    this.resultData.show_cartridge_price = Drupal.settings.show_cartridge_price || false;
    $.extend(this.resultData, Drupal.settings.globals_variables);
  },
});
